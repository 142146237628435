import { Router } from '@reach/router'
import styled from 'styled-components'
import React, { useState } from 'react'
import { AboutProperty } from '../components/AboutProperty'
import { Overview } from '../components/Overview'
import { MortgageCalculator } from '../components/MortgageCalculator'
import { EllicotLayout } from 'components/EllicotLayout'
import { GoogleMap, MarkerType } from '../components/GoogleMapProps'
import { ListingType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import { PropertyDetails } from '../components/PropertyDetails'
import { FlexDiv } from 'design-system/FlexDiv'
import Modal from 'react-modal'
import ScheduleModal from '../components/ScheduleModal'
import { toast, ToastContainer, useToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RequestDetailsModal from '../components/RequestDetailsModal'
import { CommunitySearch } from 'components/CommunitySearch'
import { Contact } from 'components/Contact'
import { Input } from '../design-system/Input'
import Axios from 'axios'
import { RelatedListing } from 'components/RelatedListing'
import { RelatedListingCarousel } from 'components/RelatedListingCarousel'
import NumberFormat from 'react-number-format'

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  margintop: 20px;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  width: 100%;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: 'Montserrat';
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  color: black;
  -webkit-font-smoothing: antialiased;
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: white;
  -webkit-font-smoothing: antialiased;
`

type ListingPageProps = {
  path: string
  mlsNumber?: string
}

const ListingPage: React.FunctionComponent<ListingPageProps> = props => {
  const [listing, setListing] = React.useState<ListingType>()
  const [scheduleModal, setScheduleModal] = useState(false)
  const [requestModal, setRequestModal] = useState(false)
  const [relatedProperty, setRelatedProperty] = useState([])
  const [spRelatedProperty, setSpRelatedProperty] = useState([])
  const [requestForm, setRequestForm] = useState({
    mlsNumber: '',
    name: undefined,
    email: undefined,
    phone: undefined,
    underContract: undefined,
    additionalDetails: undefined,
  })

  React.useEffect(() => {
    if (props.mlsNumber) {
      services.repliers
        .getListingDetail({ mlsNumber: props.mlsNumber })
        .then(res => {
          setListing(res)
          fetch('/.netlify/functions/listings', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(res),
          })
            .then(el => el.json())
            .then(e => {
              let newArr = []
              let sliceArray = e.listings.slice(0, 16)
              // let array = resp.data.listings
              let array = sliceArray
              var i,
                j,
                temparray,
                chunk = 4
              for (i = 0, j = array.length; i < j; i += chunk) {
                temparray = array.slice(i, i + chunk)
                newArr.push(temparray)
              }
              setSpRelatedProperty(newArr)
              setRelatedProperty(sliceArray)
            })
            .catch(err => {
              console.log(err)
            })

          setRequestForm({
            ...requestForm,
            mlsNumber: res.mlsNumber,
            additionalDetails:
              `Please send me information about this property at ` +
              res.address.streetNumber +
              ' ' +
              res.address.streetName +
              ' ' +
              res.address.streetSuffix +
              ', ' +
              res.address.city +
              '.',
          })
          console.log(res, ' single  property')
        })
    }
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const val = e.target.value
    if (name === 'underContract') {
      let msg = 'no'
      if (val === 'on') {
        msg = 'yes'
      }
      setRequestForm({
        ...requestForm,
        underContract: msg,
      })
      return
    }
    setRequestForm({
      ...requestForm,
      [name]: val,
    })
    console.log(requestForm)
  }

  const requestInfo = async () => {
    try {
      const res = await fetch('/.netlify/functions/listingInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestForm),
      })
      if (res.ok) {
        toast.success('Received. Elicott will send you a response shortly')
      }
    } catch (err) {
      console.log(err)
    }
  }

  let marker: MarkerType
  if (listing) {
    marker = {
      location: new google.maps.LatLng(
        parseFloat(listing.map.latitude),
        parseFloat(listing.map.longitude),
      ),
    }
  }

  return (
    <EllicotLayout>
      <ToastContainer />
      {listing ? (
        <div style={{ background: '#f5f5f5' }}>
          <Modal
            isOpen={scheduleModal}
            onRequestClose={() => {
              setScheduleModal(false)
            }}
          >
            <ScheduleModal
              listing={listing}
              closeModalSuccess={() => {
                setScheduleModal(false)
                toast.success(
                  'We have received your appointment request - a team member will get to you shortly.',
                )
              }}
              setScheduleModal={setScheduleModal}
            />
          </Modal>
          <Modal
            isOpen={requestModal}
            onRequestClose={() => {
              setRequestModal(false)
            }}
          >
            <RequestDetailsModal
              listing={listing}
              closeModalSuccess={() => {
                setRequestModal(false)
                toast.success(
                  'We will contact you with more details for this listing shortly.',
                )
              }}
              setRequestModal={setRequestModal}
            />
          </Modal>
          <ListWrapper className="listWrapper" style={{ width: '85%' }}>
            <div style={{ flex: '0 0 63%' }}>
              <AboutProperty
                listing={listing}
                setScheduleModal={setScheduleModal}
                setRequestModal={setRequestModal}
              />
              <FlexDiv
                style={{ overflow: 'hidden', width: '100%', height: '300px' }}
                className="propGmapsWrapper"
              >
                <GoogleMap center={marker.location} markers={[marker]} />
              </FlexDiv>
            </div>
            <div
              style={{ flex: '0 0 37%', margin: '16.25% 0px 0px 1%' }}
              className="propSideBar desktopOnly"
            >
              <FlexDiv className="overviewCards overviewCards2 overviewCards3">
                <h3> Request More Info</h3>
                <p>
                  Please leave your contact information below and we will get
                  back to you with additional details about this property.
                </p>
                <Input
                  label="First and Last Name:"
                  type="text"
                  name="name"
                  className="contactFormInput contactFormInput2"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                  }}
                  onChange={handleChange}
                />
                <Input
                  name="email"
                  type="email"
                  label="Email:"
                  className="contactFormInput contactFormInput2"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                  }}
                  onChange={handleChange}
                />
                <NumberFormat
                  name="phone"
                  type="tel"
                  label="Phone Number:"
                  placeholder={'Enter phone number'}
                  format="(###) ###-####"
                  className="contactFormInput contactFormInput2"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                    paddingLeft: '15px',
                  }}
                  onChange={handleChange}
                />

                <div style={{ transform: 'translateX()' }}>
                  <FlexDiv>
                    <Label
                      style={{
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '13px',
                        fontFamily: 'Montserrat-Light',
                        marginBottom: '0.5em',
                      }}
                    >
                      Are you currently working with a REALTOR?
                    </Label>
                  </FlexDiv>
                  <div
                    className="contactInputWrapper"
                    onChange={handleChange}
                    style={{ margin: '0 0 7.5px' }}
                  >
                    <div>
                      <input
                        type="radio"
                        id="yes"
                        name="underContract"
                        checked={requestForm.underContract === 'yes'}
                        style={{
                          margin: '5px 0px',
                          fontFamily: 'Montserrat-Light !important',
                        }}
                      />
                      <RadioLabel
                        for="yes"
                        style={{
                          fontSize: '13px',
                          fontFamily: 'Montserrat-Light !important',
                        }}
                      >
                        Yes
                      </RadioLabel>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="no"
                        name="underContract"
                        checked={requestForm.underContract === 'no'}
                        value={'no'}
                        style={{ margin: '5px 0px' }}
                      />
                      <RadioLabel
                        for="no"
                        style={{
                          fontSize: '13px',
                          fontFamily: 'Montserrat-Light !important',
                        }}
                      >
                        No
                      </RadioLabel>
                    </div>
                  </div>
                </div>
                <Input
                  name="additionalDetails"
                  value={requestForm.additionalDetails}
                  lines={4}
                  onChange={handleChange}
                  className="myInput contactFormInput contactFormInput3"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    paddingLeft: '0.75em',
                    paddingTop: '0.75em',
                  }}
                />
                <ButtonSubscribe
                  style={{
                    fontFamily: 'Montserrat-Bold',
                    letterSpacing: '0px',
                    padding: '10px',
                    placeContent: 'center',
                    margin: '0.5em 0.5em',
                  }}
                  onClick={requestInfo}
                >
                  REQUEST INFO
                </ButtonSubscribe>
              </FlexDiv>
            </div>
          </ListWrapper>
          {/* <FlexDiv
              style={{ overflow: 'hidden', width: '100%', height: '500px' }}
            >
              <GoogleMap center={marker.location} markers={[marker]} />
            </FlexDiv> */}
        </div>
      ) : (
        <div>Listing not found</div>
      )}
      <RelatedListingCarousel
        property={spRelatedProperty}
        className="propNew Listing"
      />

      {/* <RelatedListing listings={relatedProperty} /> */}
      <Contact />
    </EllicotLayout>
  )
}

const ListWrapper = styled.div`
  margin: auto;
  // max-width: 80%;
  display: flex;
  padding: 1em 0 2em;
`

const ListingPageRouter: React.FunctionComponent = () => {
  return (
    <Router>
      <ListingPage path="/listing/:mlsNumber" />
    </Router>
  )
}

export default ListingPageRouter
