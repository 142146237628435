import React from 'react'
import styled from 'styled-components'
import { ListingType } from '@services/repliers/repliers-services.type'
import { FlexDiv } from 'design-system/FlexDiv'
import CurrencyFormat from 'react-currency-format'

const DetailsArea = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
`
const InfoArea = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  width: 80%;
`

const Info = styled.div`
  justify-content: space-between;
  flex-wrap: wrap
  display: flex;
  flex-direction: column
  `

const InfoText = styled.p`
  font-size: 0.9em;
  font-family: CentraNo2-Book;
  color: #1c1c1c;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
`

const InfoTitle = styled.h1`
  font-size: 1em;
  font-family: CentraNo2-Book;
  color: red;
`

const InfoPlan = styled.p`
  font-family: CentraNo2-Medium;
  font-size: 0.8em;
`

const DetailsTag = styled.div`
  font-family: CentraNo2-Medium;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #1c1c1c;
`

export const PropertyDetails: React.FunctionComponent<{
  listing: ListingType
}> = ({ listing }) => {



  return (
    <div>
      <DetailsArea className="propDetails">
        <DetailsTag>
          <h1 className="details">Details:</h1>
          <Info>
            {' '}
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>MLS Number: </strong>
              </InfoText>{' '}
              <InfoText> {listing.mlsNumber || 'N/A'}</InfoText>
            </FlexDiv>
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Property Type: </strong>
              </InfoText>
              <InfoText> {listing.details.propertyType || 'N/A'}</InfoText>
            </FlexDiv>
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong> Property Tax: </strong>
              </InfoText>
              <InfoText>
                <CurrencyFormat
            value={parseFloat(listing.taxes.annualAmount)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value: any) => {
              return <div style={{ display: 'inline' }}>{value}</div>
            }}
          />
                
              </InfoText>
            </FlexDiv>
            {/* <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong> Last Updated: </strong>
              </InfoText>{' '}
              <InfoText>{listing.timestamps.listingUpdated}</InfoText>
            </FlexDiv>
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong> Listed On: </strong>
              </InfoText>{' '}
              <InfoText>{listing.timestamps.listingEntryDate}</InfoText>
            </FlexDiv>
            <InfoPlan>
              <em>Floor Plans will be coming soon</em>
            </InfoPlan> */}
          </Info>
        </DetailsTag>
        <DetailsTag className="interior">
          <h1>Interior:</h1>
          <Info>
            {' '}
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Bedrooms: </strong>
              </InfoText>
              <InfoText>{listing.details.numBedrooms || 'N/A'}</InfoText>
            </FlexDiv>{' '}
            {/* <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Heat Source: </strong>
              </InfoText>
              <InfoText>NO HEAT SOURCE IN API</InfoText>
            </FlexDiv>{' '} */}
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Bathrooms: </strong>
              </InfoText>
              <InfoText>{listing.details.numBathrooms || 'N/A'}</InfoText>
            </FlexDiv>{' '}
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Sq Ft: </strong>
              </InfoText>
              <InfoText>{listing.details.sqft || 'N/A'} ft²</InfoText>
            </FlexDiv>{' '}
            {/* <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Drainage: </strong>
              </InfoText>
              <InfoText>NO DRAINAGE IN API</InfoText>
            </FlexDiv> */}
          </Info>
        </DetailsTag>
        <DetailsTag className="exterior">
          <h1>Exterior:</h1>
          <Info>
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Parking Space:</strong>{' '}
              </InfoText>
              <InfoText>{listing.details.numParkingSpaces || 'N/A'}</InfoText>
            </FlexDiv>
            {/* <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Driveway: </strong>{' '}
              </InfoText>

              <InfoText>NO DRIVEWAY IN API</InfoText>
            </FlexDiv> */}
            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Garage: </strong>
              </InfoText>
              <InfoText>{listing.details.garage || 'N/A'}</InfoText>
            </FlexDiv>

            <FlexDiv>
              <InfoText style={{ marginRight: 5 }}>
                <strong>Property Type: </strong>
              </InfoText>
              <InfoText>{listing.style || 'N/A'} </InfoText>
             
             </FlexDiv>
          </Info>
        </DetailsTag>
      </DetailsArea>
    </div>
  )
}
