import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { ListingType } from '@services/repliers/repliers-services.type'
import { appConfig } from 'config'
// import { Overview } from '../components/Overview'
import LeftArrow from '../assets/icons/LeftArrow.svg'
import { Link } from 'gatsby'
import CurrencyFormat from 'react-currency-format'
// import { MortgageCalculator } from '../components/MortgageCalculator'
import { PropertyDetails } from '../components/PropertyDetails'
import { Carousel } from 'react-responsive-carousel'
import { Input } from '../design-system/Input'
import NumberFormat from 'react-number-format'

const RadioLabel = styled.label<{ for: any }>`
  font-family: 'Montserrat';
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  color: black;
  -webkit-font-smoothing: antialiased;
`
const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  margintop: 20px;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  width: 100%;
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: white;
  -webkit-font-smoothing: antialiased;
`

const Container = styled.div`
  display: flex;
  background-color: #f5f5f5;
  flex-direction: column;
  max-width: 40em;
  color: #1c1c1c;
`

const AddressPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -6%;
  font-family: 'CentraNo2-Bold';
  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #1c1c1c;
`

const AddressSale = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-family: 'Montserrat';
    font-size: 1.125em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c1c1c;
  }
`
const AboutTag = styled.div`
  font-family: CentraNo2-Medium;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #1c1c1c;
  margin-top: 1%;
`

const PropertyDescription = styled.div`
  font-family: 'Montserrat';
  font-size: 1.125em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #1c1c1c;
`

const PropertyTitle = styled.h1`
  -webkit-font-smoothing: antialiased;
  margin: 0 0 1.25em;
  font-weight: 500;
`
const PropertySubTitle = styled.p`
  font-family: CentraNo2-Light;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 1em;
`

const PropertySubText = styled.p`
  font-size: 0.9em;
  font-family: 'Montserrat';
`

const BackSearchLink = styled.a`
  font-family: 'Montserrat';
  font-weight: 500;
  margin: 2em 0;
  cursor: pointer;
`

export const AboutProperty: React.FunctionComponent<{
  listing: ListingType
  setScheduleModal: Function
  setRequestModal: Function
}> = ({ listing, setScheduleModal, setRequestModal }) => {
  const [mainImage, setMainImage] = useState(listing.images[0])
  const [requestForm, setRequestForm] = useState({
    name: undefined,
    email: undefined,
    phone: undefined,
    underContract: undefined,
    additionalDetails: undefined,
  })
  const handleImageClick = (index: number) => {
    setMainImage(listing.images[index])
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const val = e.target.value

    setRequestForm({
      ...requestForm,
      [name]: val,
    })
  }

  const requestInfo = () => {
    console.log(requestForm)
  }
  return (
    <Container
      className="aboutPropContainer"
      style={{ marginLeft: '-1em', marginRight: '2em' }}
    >
      <FlexDiv>
        <BackSearchLink className="back2Search">
          <Link
            to="/properties"
            style={{ textDecoration: 'none', color: '#212121' }}
          >
            {' '}
            <img
              src={LeftArrow}
              style={{ height: '15px', marginRight: '10px' }}
            />{' '}
            Back to Search Listings
          </Link>
        </BackSearchLink>
      </FlexDiv>
      <AddressPrice>
        <PropertyTitle className="propTitleContainer">
          {listing.address.streetNumber} {listing.address.streetName}{' '}
          {listing.address.streetSuffix}
        </PropertyTitle>
        <PropertyTitle className="propTitleContainer">
          <CurrencyFormat
            value={parseFloat(listing.listPrice)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value: any) => {
              return <div>{value}</div>
            }}
          />
        </PropertyTitle>
      </AddressPrice>
      <AddressSale className="propAddressWrapper">
        <PropertySubTitle>
          {listing.address.city}, {listing.address.state} {listing.address.zip}
        </PropertySubTitle>
        <PropertySubTitle>{listing.type}</PropertySubTitle>
      </AddressSale>
      {listing.images.length > 0 && (
        <Carousel>
          {listing.images.map((url: string) => {
            return (
              <div>
                <img
                  src={`${appConfig.repliers.CDN_URL}/${url}`}
                  style={{ width: '100%' }}
                />
              </div>
            )
          })}
        </Carousel>
      )}

      <AboutTag className="aboutPropDetails">
        <h1>Property Summary:</h1>
      </AboutTag>
      <PropertyDescription className="propTextDetailsWrapper">
        <PropertySubText>
          {listing.details.description}
          <div className="mobileOnly" style={{ marginTop: '2em' }}>
            <FlexDiv className="overviewCards overviewCards2">
              <h3> Request More Info </h3>
              <p>
                Fill out your info and receive full-access to information with
                regards to progression, availability, floor plans, pricing and
                complete listing details.
              </p>
              <Input
                label="First and Last Name:"
                name="name"
                className="contactFormInput contactFormInput2"
                style={{
                  marginRight: 5,
                  background: '#fff',
                  color: '#000',
                  border: '1px solid lightgray',
                  height: '40px',
                }}
                value={requestForm.name}
                onChange={handleChange}
              />
              <Input
                name="email"
                label="Email:"
                className="contactFormInput contactFormInput2"
                style={{
                  marginRight: 5,
                  background: '#fff',
                  color: '#000',
                  border: '1px solid lightgray',
                  height: '40px',
                }}
                value={requestForm.email}
                onChange={handleChange}
              />
              <NumberFormat
                name="phone"
                label="Phone Number:"
                placeholder={'Enter phone number'}
                format="(###) ###-####"
                className="contactFormInput contactFormInput2"
                style={{
                  marginRight: 5,
                  background: '#fff',
                  color: '#000',
                  border: '1px solid lightgray',
                  height: '40px',
                }}
                value={requestForm.phone}
                onChange={handleChange}
              />

              <div
                style={{ transform: 'translateX()' }}
                className="realtorRadioWrapper"
              >
                <FlexDiv>
                  <Label
                    style={{
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '13px',
                      fontFamily: 'Montserrat-Light',
                      marginBottom: '0.5em',
                    }}
                  >
                    Are you currently working with a REALTOR?
                  </Label>
                </FlexDiv>
                <div
                  className="contactInputWrapper"
                  onChange={handleChange}
                  style={{ margin: '0 0 7.5px' }}
                >
                  <div>
                    <input
                      type="radio"
                      id="yes"
                      name="underContract"
                      checked={requestForm.underContract === 'yes'}
                      value={'yes'}
                      style={{
                        margin: '5px 0px 0px',
                        fontFamily: 'Montserrat-Light !important',
                      }}
                    />
                    <RadioLabel
                      for="yes"
                      style={{
                        fontSize: '13px',
                        fontFamily: 'Montserrat-Light !important',
                      }}
                    >
                      Yes
                    </RadioLabel>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="no"
                      name="underContract"
                      checked={requestForm.underContract === 'no'}
                      value={'no'}
                      style={{ margin: '5px 0px 0px' }}
                    />
                    <RadioLabel
                      for="no"
                      style={{
                        fontSize: '13px',
                        fontFamily: 'Montserrat-Light !important',
                      }}
                    >
                      No
                    </RadioLabel>
                  </div>
                </div>
              </div>
              <Input
                name="additionalDetails"
                defaultValue={
                  `Please send me information about this property at ` +
                  listing.address.streetNumber +
                  ' ' +
                  listing.address.streetName +
                  ' ' +
                  listing.address.streetSuffix +
                  ', ' +
                  listing.address.city +
                  '.'
                }
                value={requestForm.additionalDetails}
                lines={4}
                onChange={handleChange}
                className="myInput contactFormInput contactFormInput3"
                style={{
                  marginRight: 5,
                  background: '#fff',
                  color: '#000',
                  border: '1px solid lightgray',
                  paddingLeft: '0.75em',
                  paddingTop: '0.75em',
                }}
              />
              <ButtonSubscribe
                style={{
                  fontFamily: 'Montserrat-Bold',
                  letterSpacing: '0px',
                  padding: '10px',
                  placeContent: 'center',
                  margin: '0.5em 0.5em',
                }}
                onClick={requestInfo}
              >
                REQUEST INFO
              </ButtonSubscribe>
            </FlexDiv>
          </div>
          <br className="desktopOnly" />
          <br />
          <span className="desktopOnly"> </span> {listing.details.extras}
          <PropertyDetails listing={listing} />
        </PropertySubText>
      </PropertyDescription>
    </Container>
  )
}
