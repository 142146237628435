import React from 'react'
import { FlexDiv } from '../design-system/FlexDiv'
import {
  GoogleMap as GMappers,
  Marker,
  GoogleMapProps,
  InfoWindow,
  Polygon,
} from '@react-google-maps/api'
import Cookies from 'js-cookie'


const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        hue: '#fff',
      },
      {
        saturation: -100,
      },
      {
        lightness: -100,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        hue: '#fff',
      },
      {
        saturation: -100,
      },
      {
        lightness: 55,
      },
      {
        visibility: 'on',
      },
    ],
  },
  // {
  //     "featureType": "administrative",
  //     "elementType": "all",
  //     "stylers": [
  //         {
  //             "hue": "#000000"
  //         },
  //         {
  //             "saturation": 0
  //         },
  //         {
  //             "lightness": -100
  //         },
  //         {
  //             "visibility": "on"
  //         }
  //     ]
  // },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        hue: '#ffffff',
      },
      {
        saturation: -100,
      },
      {
        lightness: 10,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        hue: '#000000',
      },
      {
        saturation: -100,
      },
      {
        lightness: -100,
      },
      {
        visibility: 'on',
      },
    ],
  },
  // {
  //     "featureType": "road.local",
  //     "elementType": "all",
  //     "stylers": [
  //         {
  //             "hue": "#ffffff"
  //         },
  //         {
  //             "saturation": -100
  //         },
  //         {
  //             "lightness": 100
  //         },
  //         {
  //             "visibility": "on"
  //         }
  //     ]
  // },
  // {
  //     "featureType": "water",
  //     "elementType": "geometry",
  //     "stylers": [
  //         {
  //             "hue": "#ffffff"
  //         },
  //         {
  //             "saturation": -100
  //         },
  //         {
  //             "lightness": 100
  //         },
  //         {
  //             "visibility": "on"
  //         }
  //     ]
  // },
  // {
  //     "featureType": "transit",
  //     "elementType": "labels",
  //     "stylers": [
  //         {
  //             "hue": "#000000"
  //         },
  //         {
  //             "saturation": 0
  //         },
  //         {
  //             "lightness": -100
  //         },
  //         {
  //             "visibility": "on"
  //         }
  //     ]
  // },
  // {
  //     "featureType": "landscape",
  //     "elementType": "labels",
  //     "stylers": [
  //         {
  //             "hue": "#000000"
  //         },
  //         {
  //             "saturation": -100
  //         },
  //         {
  //             "lightness": -100
  //         },
  //         {
  //             "visibility": "on"
  //         }
  //     ]
  // },
  {
    featureType: 'road',
    elementType: 'fff',
    stylers: [
      {
        hue: '#bbbbbb',
      },
      {
        saturation: -100,
      },
      {
        lightness: 26,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#fff',
      },
      {
        saturation: -100,
      },
      {
        lightness: -3,
      },
      {
        visibility: 'on',
      },
    ],
  },
]

type Position = {
  lat: number
  lng: number
}

type MapProps = {
  onLoad?: (m: google.maps.Map) => void
  initialPosition?: Position
  markers?: MarkerType[]
  center?: google.maps.LatLng
  onCenterChanged?: GoogleMapProps['onCenterChanged']
  onSelectMarker?: (marker: MarkerType, index: number) => void
  zoom?: number
  mapTypeControl?: boolean
}

export type MarkerType = {
  id?: string
  location: google.maps.LatLng
  infoWindowComponent?: JSX.Element
  showInfoWindow?: boolean
  mapTypeControl?: boolean
}

export const GoogleMap: React.FC<MapProps> = props => {
  const [coords, setCoords] = React.useState()
  React.useEffect(() => {
    const coordsFromCookies = Cookies.get('gmapbounds')
    if (coordsFromCookies) {
      const coords = JSON.parse(coordsFromCookies)
      const mapTypeControl = false
      console.log(coords)
      console.log(coords[0])

      // const reversedCoords = MARKHAM_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // const reversedCoords = MISSISSAUGA_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // const reversedCoords3 = BRAMPTON_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // const reversedCoords4 = ETOBICOKE_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // const reversedCoords5 = VAUGHAN_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // const reversedCoords6 = AJAX_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // const reversedCoords7 = AURORA_BOUNDS.map((ll: any) => {
      //   if (coords) {
      //     return { lat: ll[1], lng: ll[0] }
      //   }
      // })

      // console.log(reversedCoords5)
      // setCoords(
      //   reversedCoords5,
        // reversedCoords2,
        // reversedCoords3,
        // reversedCoords4,
        // reversedCoords5,
        // reversedCoords6,
        // reversedCoords7,
      // )
    }
  }, [])

  return (
    <FlexDiv>
    <GMappers
      mapContainerStyle={{ width: '97.5%', height: '100%' }}
      onLoad={props.onLoad}
      zoom={props.zoom ? props.zoom : 12}
      mapTypeControl= {false}
      onCenterChanged={props.onCenterChanged}
      center={
        props.center
          ? props.center
          : props.initialPosition || { lat: 43.653225, lng: -79.383186 }
      }
      options={{ styles: mapStyles }}
      style={{ paddingTop: '2em' }}
     
    >
      {props.markers &&
        props.markers.map((marker, index) => (
          <React.Fragment key={`marker-${index}`}>
            {marker.infoWindowComponent && marker.showInfoWindow && (
              <InfoWindow position={marker.location}>
                {marker.infoWindowComponent}
              </InfoWindow>
            )}
            {coords && (
              <Polygon
                path={coords}
                // key={1}
                options={{
                  fillColor: '#ebebeb',
                  fillOpacity: 0,
                  strokeColor: '#000',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
              />
            )}
            <Marker
              key={index}
              position={marker.location}
              icon={
                marker.place
                  ? {
                      url:
                        'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                    }
                  : {
                      url:
                        'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    }
              }
              onClick={() =>
                props.onSelectMarker
                  ? props.onSelectMarker(marker, index)
                  : null
              }
            />
          </React.Fragment>
        ))}
      </GMappers>
      </FlexDiv>
  )
}
