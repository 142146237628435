import { ListingType } from '@services/repliers/repliers-services.type'
import React, { useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { FlexDiv } from 'design-system/FlexDiv'
import { Input } from 'design-system/Input'
import styled from 'styled-components'
import { services } from '@services'
import { ContactFormDataType } from '@services/lifted/send-contact-form.service'
import 'react-toastify/dist/ReactToastify.css'
import CurrencyFormat from 'react-currency-format'
import closeIcon from '../assets/icons/close-icon.svg'

const FullWidthWrapper = styled.div`
  width: 100%;
`

const RealtorWrapper = styled.span`
  top: -50em;
  font-size: 0.5%;
  position: relative;
  vertical-align: baseline;
`

const OfficeDetailTitle = styled.h5`
  font-family: Montserrat;
  font-size: 0.8em;
  margin-block-start: 1.5em;
  margin-block-end: 0.25em;
`

const OfficeDetailText = styled.p`
  font-family: Montserrat;
  font-size: 0.8em;
  margin-block-end: 0em;
  margin-block-start: 0em;
`

const ContactH1 = styled.h1`
  font-family: 'Montserrat-ExtraBold';
  text-align: left;
  margin-block-end: 0.25em;
  font-size: 1.5em !important;
  margin-top: 2em;
`

const ContactP = styled.p`
  font-family: Montserrat;
  font-size: 0.9em;
`

const InputLabel = styled.span`
  font-family: Montserrat;
  font-size: 0.75em;
  display: flex;
  margin: 0 1em;
  transform: translateY(0.25em);
`

const Label = styled.span`
  font-family: 'Montserrat-SemiBold' !important;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
`

const LandingInput = styled.input`
  background: transparent;
  border: 2px solid #adadad;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  width: 80%;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: Montserrat;
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  -webkit-font-smoothing: antialiased;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 50%;
  cursor: pointer;
  margintop: 20px;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`
const ScheduleModal: React.FunctionComponent<{
  listing: ListingType
  closeModalSuccess: Function
  setScheduleModal: Function
}> = props => {
  const [formData, setFormData] = useState<ContactFormDataType>({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    dates: [new Date(), new Date()],
    additionalDetails: '',
    timeslot: undefined,
    underContract: 'no',
    listing: props.listing,
  })
  const submitForm = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    console.log(formData)
    const res = await services.lifted.sendScheduleForm({
      ...formData,
      dates: [
        formData.dates[0].toDateString(),
        formData.dates[1].toDateString(),
      ],
    })
    console.log(res)
    if (res) {
      setLoading(false)
      props.closeModalSuccess()
    }
  }
  const [loading, setLoading] = useState(false)

  const options = [
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
  ]
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newForm = {
      ...formData,
      [name]: value,
    }

    setFormData(newForm)
  }
  const defaultOption = options[0]
  return (
    <div>
      <div
        style={{
          zIndex: 1000,
          minHeight: 600,
          display: 'flex',
          flexDirection: 'column',
        }}
        className="scheduleModal"
      >
        <FlexDiv
          className="closeContainer"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.setScheduleModal(false)
          }}
        >
          <img src={closeIcon} />
        </FlexDiv>
        <ContactH1 style={{ marginTop: '-10px' }}>
          {' '}
          {props.listing.address.streetNumber +
            ' ' +
            props.listing.address.streetName +
            ' ' +
            props.listing.address.streetSuffix}
        </ContactH1>

        <p style={{ margin: 0 }}>
          {props.listing.address.area +
            ', ' +
            props.listing.address.state +
            ' ' +
            props.listing.address.zip}
        </p>
        <p style={{ margin: 0 }}>
          {props.listing.type} :{' '}
          <CurrencyFormat
            value={parseFloat(props.listing.listPrice)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value: any) => {
              return <div style={{ display: 'inline' }}>{value}</div>
            }}
          />
        </p>

        <FlexDiv vert justify="center" style={{ flex: '2' }}>
          <ContactP style={{ marginBottom: '0.5em', marginTop: '1em' }}>
            Leave your details below to schedule a showing to view this
            property:
          </ContactP>
                    <FlexDiv style={{ marginTop: 0, flexWrap: 'nowrap' }} className="nameInputWrapper">

            <Input
              label="First Name"
              name="fname"
              style={{ marginRight: 5 }}
              onChange={handleChange}
              className="myInput"
            />
            <Input
              label="Last Name"
              name="lname"
              style={{ marginLeft: 5 }}
              onChange={handleChange}
              className="myInput"
            />
          </FlexDiv>
                    <FlexDiv style={{ marginTop: -12.5 }} className="nameInputWrapper">

            <Input
              name="email"
              label="Email Address"
              style={{ marginRight: 5 }}
              onChange={handleChange}
              className="myInput"
            />
            <Input
              name="phone"
              label="Phone Number"
              style={{ marginLeft: 5 }}
              onChange={handleChange}
              className="myInput"
            />
          </FlexDiv>
          <FlexDiv style={{ marginTop: -12.5 }}>
            <Input
              name="additionalDetails"
              label="Additional details"
              lines={3}
              onChange={handleChange}
              className="myInput"
            />
          </FlexDiv>
          <FlexDiv vert style={{ marginTop: -12.5 }}>
            <FlexDiv container>
              <DateRangePicker
                onChange={(e: any) => {
                  console.log(e)
                  setFormData({ ...formData, dates: e })
                }}
                style={{ padding: 10 }}
                value={formData.dates}
              />
              <Dropdown
                options={options}
                onChange={e => {
                  console.log(e)
                  setFormData({
                    ...formData,
                    timeslot: e.value,
                  })
                }}
                className="dateDropDown"
                value={defaultOption}
              />
            </FlexDiv>
            <FlexDiv>
              <Label style={{ margin: '15px 0', fontSize: '0.8em', fontWeight: '600' }}>
                Are you currently under a contract with a REALTOR?
              </Label>
            </FlexDiv>

            <FlexDiv vert style={{ marginTop: '0em' }}>
              <div onChange={handleChange}>
                <input
                  type="radio"
                  id="yes"
                  name="underContract"
                  checked={formData.underContract === 'yes'}
                  value={'yes'}
                />
                <RadioLabel for="yes">Yes</RadioLabel>

                <input
                  type="radio"
                  id="no"
                  name="underContract"
                  checked={formData.underContract === 'no'}
                  value={'no'}
                  style={{ marginLeft: 20 }}
                />
                <RadioLabel for="no">No</RadioLabel>
              </div>

              <FlexDiv>
                {!loading ? (
                  <ButtonSubscribe
                    onClick={submitForm}
                    style={{ marginTop: 30 }}
                  >
                    Schedule
                  </ButtonSubscribe>
                ) : (
                  <p style={{ color: 'black' }}>Processing request..</p>
                )}
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </div>
    </div>
  )
}

export default ScheduleModal
