import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Link } from 'gatsby'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import areaIcon from '../assets/icons/area.svg'
import bedIcon from '../assets/icons/bed.svg'
import bathIcon from '../assets/icons/bath.svg'
import { appConfig } from 'config'
import CurrencyFormat from 'react-currency-format'
import userIcon from '../assets/icons/person.svg'






const ListingCard = styled.div`
  height: 370px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`
const MyDiv = styled.div`
  display: flex;
  position: absolute;
  z-index: 100;
  width: 100%;

  color: white;
`
const MyDiv2 = styled.div`
  display: flex;
  padding: 0.5em 2.5em;
  align-items: center;
  justify-content: center;
  background: black;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`
const MyDiv3 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: grey;
  padding: 0.5em 2.5em;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  -webkit-font-smoothing: antialiased;
  border-radius: 0;
  text-transform: uppercase;
  justify-content: center;
`

const ContentDiv = styled.div`
  max-height: 50%;
  word-break: break-all;
  word-wrap: break-word;
`

const PriceTitle = styled.h2`
  font-size: 20px !important;
  letter-spacing: 0.5px;
  font-family: CentraNo2-Medium;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
`

const FlexDiv3 = styled.div`
  justify-content: flex-end;
`

const AddFavouriteTxt = styled.p`
  font-size: 0.8em;
  font-weight: 100;
  font-family: 'Montserrat-Medium';
  -webkit-font-smoothing: antialiased;
  margin: 10px;
`

const ListingAddress = styled.p`
  font-size: 20px !important;
  font-weight: 100;
  font-family: 'Montserrat-Medium';
  -webkit-font-smoothing: antialiased;
  margin: 0 0 10px;
`

const ListingDetail = styled.p`
  font-size: 0.7em;
  font-weight: 100;
  font-family: 'Montserrat-Medium';
  -webkit-font-smoothing: antialiased;
`
const BrokerageName = styled.p`
  font-size: 0.7em;
  font-weight: 100;
  font-family: 'Montserrat-Medium';
  -webkit-font-smoothing: antialiased;
  margin: 10px 0;
`

const NoStyleLink = styled(Link)`
  :link {
    text-decoration: inherit;
    color: inherit;
  }

  :visited {
    text-decoration: inherit;
    color: inherit;
  }
  width: 100%;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  margintop: 20px;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  width: 100%;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: 'Montserrat';
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  color: black;
  -webkit-font-smoothing: antialiased;
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: white;
  -webkit-font-smoothing: antialiased;
`


const DivWrapper = styled.div`
  padding: 2em 0 3em;
`

export const RelatedListingCarousel: React.FunctionComponent = ({ property }) => {
  const redToPage=(listing)=>{
    window.location.href=`/listing/${listing.mlsNumber}`
  }
  return (
    <DivWrapper className="discoverCommunities carouselProps similarListingSec">
      <FlexDiv className="propCommunityCards">
        <h1 className="similarListings"> Similar Listings:</h1>
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          interval={15000}
          autoPlay={true}
          className="desktopOnly">
          {
            property.map(chunk => (
              <>
                <div className="communityCardDivWrapper">
                  {chunk.map((listing, index) => (
                    <FlexDiv className="communityCards">
                      <FlexDiv style={{ width: "" }} className="communityCardWrapper">
                        <div
                          onClick={e=>redToPage(listing)}
                          style={{
                            display: 'flex',
                            // flex: 'auto',
                            width: '31.5%',
                            margin: '10px',
                            background: '#fff',
                          }}
                          className="propertyCard"
                        >
                          <NoStyleLink to={`/listing/${listing.mlsNumber}`}>
                            <ListingCard key={index} className="listingCardBox">
                              <MyDiv>
                                <FlexDiv>
                                  <MyDiv2>{listing.lastStatus}</MyDiv2>
                                </FlexDiv>
                                <FlexDiv3>
                                  <MyDiv3>{listing.type}</MyDiv3>
                                </FlexDiv3>
                              </MyDiv>
                              <img
                                src={`${appConfig.repliers.CDN_URL}/${listing.images[0]}`}
                                style={{
                                  maxHeight: '50%',
                                  minHeight: '50%',
                                  objectFit: 'cover',
                                }}
                                className="propImgRendering"
                              />

                              <ContentDiv>
                                <div style={{ padding: 15 }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignContent: 'center',
                                      paddingTop: 10,
                                    }}
                                  >
                                    <div style={{ display: 'flex', width: '35%' }} className="priceTitleCard">
                                      <PriceTitle style={{ margin: 0 }}>
                                        <CurrencyFormat
                                          value={parseFloat(listing.listPrice)}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                          renderText={value => <div>{value}</div>}
                                        />
                                      </PriceTitle>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '65%',
                                        alignContent: 'flex-end',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <ListingAddress className="desktopOnly listingAddressTitle">
                                        {listing.address.streetNumber}{' '}
                                        {listing.address.streetName}{' '}
                                        {listing.address.streetSuffix}
                                      </ListingAddress>
                                    </div>
                                  </div>
                                  <ListingAddress className="mobileOnlyOD mobileOnlyAddress" style={{ marginTop: '1em' }}>
                                    {listing.address.streetNumber}{' '}
                                    {listing.address.streetName}{' '}
                                    {listing.address.streetSuffix}
                                  </ListingAddress>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      paddingTop: '0.75em'
                                    }}
                                    className="newRelatedListingLineDeets"
                                  >
                                    <div style={{ display: 'flex', width: '33%' }} className="propIconCard">
                                      <img
                                        src={areaIcon}
                                        style={{ width: 12, marginRight: 8 }}
                                      />
                                      <ListingDetail>
                                        {listing.details.sqft || "N/A"} ft²
                      </ListingDetail>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '30%',
                                        placeContent: 'center',
                                      }}
                                      className="propIconCard"
                                    >
                                      <img
                                        src={bedIcon}
                                        style={{ width: 12, marginRight: 8 }}
                                      />
                                      <ListingDetail>
                                        {listing.details.numBedrooms || "N/A"} Beds
                      </ListingDetail>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '30%',
                                        placeContent: 'center',
                                      }}
                                      className="propIconCard"
                                    >
                                      <img
                                        src={bathIcon}
                                        style={{ width: 12, marginRight: 8 }}
                                      />
                                      <ListingDetail>
                                        {listing.details.numBathrooms || "N/A"} Baths
                      </ListingDetail>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      width: '100%',
                                      margin: '1.25em 0 0',
                                      borderTop: '1px solid #0000005c',
                                      paddingTop: '0.5em',
                                    }}
                                    className="ellicottLineSignature"
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: '50%',
                                      }}
                                    >
                                      <img
                                        src={userIcon}
                                        style={{ width: 12, marginRight: 8 }}
                                        className="ellicottSignatureIcon"
                                      />

                                      <BrokerageName style={{ fontSize: 12 }}>
                                        Ellicott Realty, Inc.
                      </BrokerageName>
                                    </div>
                                  </div>
                                </div>
                              </ContentDiv>
                            </ListingCard>
                          </NoStyleLink>
                        </div>

                      </FlexDiv>
                    </FlexDiv>
                  ))}

                </div>
              </>
            ))
          }
        </Carousel>
      </FlexDiv>
    </DivWrapper>
  )
}
