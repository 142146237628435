import { ListingType } from '@services/repliers/repliers-services.type'
import React, { useState } from 'react'
import 'react-dropdown/style.css'
import { FlexDiv } from 'design-system/FlexDiv'
import { Input } from 'design-system/Input'
import styled from 'styled-components'
import { services } from '@services'
import { ContactFormDataType } from '@services/lifted/send-contact-form.service'
import 'react-toastify/dist/ReactToastify.css'
import CurrencyFormat from 'react-currency-format'
import closeIcon from '../assets/icons/close-icon.svg'

const FullWidthWrapper = styled.div`
  width: 100%;
`

const RealtorWrapper = styled.span`
  top: -50em;
  font-size: 0.5%;
  position: relative;
  vertical-align: baseline;
`

const OfficeDetailTitle = styled.h5`
  font-family: 'Montserrat';
  font-size: 0.8em;
  margin-block-start: 1.5em;
  margin-block-end: 0.25em;
`

const OfficeDetailText = styled.p`
  font-family: 'Montserrat';
  font-size: 0.8em;
  margin-block-end: 0em;
  margin-block-start: 0em;
`

const ContactH1 = styled.h1`
  font-family: 'Montserrat-ExtraBold';
  text-align: left;
  margin-block-end: 0em;
  font-size: 1.5em !important;
`

const ContactP = styled.p`
  font-family: 'Montserrat';
  font-size: 0.9em;
`

const InputLabel = styled.span`
  font-family: 'Montserrat';
  font-size: 0.75em;
  display: flex;
  margin: 0 1em;
  transform: translateY(0.25em);
`

const Label = styled.span`
  font-family: 'Montserrat-SemiBold';
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
`

const LandingInput = styled.input`
  background: transparent;
  border: 2px solid #adadad;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  width: 80%;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: 'Montserrat';
  margin: 0 0.5em;
  transform: translateY(-0.125em);
  -webkit-font-smoothing: antialiased;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 50%;
  cursor: pointer;
  margintop: 20px;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`
const RequestDetailsModal: React.FunctionComponent<{
  listing: ListingType
  closeModalSuccess: Function
  setRequestModal: (input: boolean) => void
}> = props => {
  const [formData, setFormData] = useState<ContactFormDataType>({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    additionalDetails: '',
    listing: props.listing,
  })
  const submitForm = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    const res = await services.lifted.sendDetailsForm(formData)
    if (res) {
      setLoading(false)
      props.closeModalSuccess()
    }
  }
  const [loading, setLoading] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newForm = {
      ...formData,
      [name]: value,
    }

    setFormData(newForm)
  }

  return (
    <div
      style={{
        zIndex: 1000,
        minHeight: 525,
        width: 500,
        padding: '20px 20px 0',
      }}
      className="requestDetails"
    >
      <FlexDiv className="closeContainer" style={{ cursor: 'pointer' }}>
        <img
          src={closeIcon}
          onClick={() => {
            props.setRequestModal(false)
          }}
        />
      </FlexDiv>
      <ContactH1 style={{ marginTop: '-10px' }}>
        {' '}
        {props.listing.address.streetNumber +
          ' ' +
          props.listing.address.streetName +
          ' ' +
          props.listing.address.streetSuffix}
      </ContactH1>

      <p>
        {props.listing.address.area +
          ', ' +
          props.listing.address.state +
          ' ' +
          props.listing.address.zip}
      </p>
      <p>
        {props.listing.type} :{' '}
        <CurrencyFormat
          value={parseFloat(props.listing.listPrice)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value: any) => {
            return <div style={{ display: 'inline' }}>{value}</div>
          }}
        />
      </p>

      <FlexDiv vert justify="center" style={{ flex: '2' }}>
        <FlexDiv>
          <ContactP style={{ marginBottom: 10, marginTop: 10 }}>
            Leave your details below to receive additional info about this
            listing:
          </ContactP>
        </FlexDiv>
        <FlexDiv style={{ marginTop: 0, flexWrap: 'nowrap' }}>
          <Input
            label="First Name"
            name="fname"
            style={{ marginRight: 5 }}
            onChange={handleChange}
            className="myInput"
          />
          <Input
            label="Last Name"
            name="lname"
            style={{ marginLeft: 5 }}
            onChange={handleChange}
            className="myInput"
          />
        </FlexDiv>
        <FlexDiv style={{ marginTop: 10 }}>
          <Input
            name="email"
            label="Email Address"
            style={{ marginRight: 5 }}
            onChange={handleChange}
            className="myInput"
          />
          <Input
            name="phone"
            label="Phone Number"
            style={{ marginLeft: 5 }}
            onChange={handleChange}
            className="myInput"
          />
        </FlexDiv>
        <FlexDiv style={{ marginTop: 10 }}>
          <Input
            name="additionalDetails"
            label="Additional details"
            lines={3}
            onChange={handleChange}
            className="myInput"
          />
        </FlexDiv>
        <FlexDiv>
          <Label style={{ margin: '15px 0', fontSize: '0.8em', fontWeight: '600' }}>
                Are you currently under a contract with a REALTOR?
              </Label>
        </FlexDiv>

        <FlexDiv vert style={{ marginTop: '0em' }}>
          <div onChange={handleChange}>
            <input
              type="radio"
              id="yes"
              name="underContract"
              checked={formData.underContract === 'yes'}
              value={'yes'}
            />
            <RadioLabel for="yes">Yes</RadioLabel>

            <input
              type="radio"
              id="no"
              name="underContract"
              checked={formData.underContract === 'no'}
              value={'no'}
              checked
              style={{ marginLeft: 20 }}
            />
            <RadioLabel for="no">No</RadioLabel>
          </div>
        </FlexDiv>
        <FlexDiv vert style={{ marginTop: '0em' }}>
          <FlexDiv>
            {!loading ? (
              <ButtonSubscribe
                onClick={submitForm}
                style={{ marginTop: '2em' }}
              >
                Submit
              </ButtonSubscribe>
            ) : (
              <p style={{ color: 'black' }}>Processing request..</p>
            )}
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </div>
  )
}

export default RequestDetailsModal
